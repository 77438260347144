import { defineStore } from 'pinia'

export const useSpecialIssueStore = defineStore({
  id: 'special-issue-store',

  state: () => {
    return {
      id: null,
      title: '',
      fileName: '',
    }
  },

  actions: {
    setSpecialIssue(id: string, title: string, fileName?: string) {
      this.id = id
      this.title = title
      this.fileName = fileName
    },

    clear() {
      this.id = null
      this.title = ''
      this.fileName = ''
    },
  },

  getters: {
    getSpecialIssue: (state) => {
      return { id: state.id, fileName: state.fileName, title: state.title }
    },
  },
})
