<script setup lang="ts">
import { useField, ErrorMessage } from 'vee-validate'
import { VueTelInput } from 'vue-tel-input'
import { PhoneObject } from '@/models/form'

import 'vue-tel-input/dist/vue-tel-input.css'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
    required: false,
  },
  modelValue: {
    type: [String, Number],
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  rules: {
    type: String,
    default: '',
  },
  errorMessages: {
    type: Object,
    default: () => ({}),
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const { setErrors, validate, value } = useField(props.name, props.rules, {
  label: props.label,
  validateOnValueUpdate: false,
})

const currentPhoneObject = ref<PhoneObject>(null)

const handleInput = (_number, phoneObject) => {
  const { formatted, valid } = phoneObject
  currentPhoneObject.value = phoneObject

  emit('update:modelValue', formatted)

  if (valid) {
    validate()
  }
}

const handleBlur = () => {
  const { number, formatted, country, valid } = currentPhoneObject.value

  if (!valid) {
    if (!formatted) {
      validate()
    } else {
      setErrors(props.errorMessages.invalid)
    }
  } else {
    const prefixedNumber = formatted.startsWith(`+${country.dialCode}`)
      ? formatted
      : number

    emit('update:modelValue', prefixedNumber)
    validate()
  }
}

const preferredCountries = ['gb', 'us', 'ca', 'cn', 'au']
</script>

<template>
  <div>
    <span class="mb-1 block text-xs font-semibold">
      {{ label }} <span v-if="required" class="text-error">*</span>
    </span>

    <VueTelInput
      v-model="value"
      :preferred-countries="preferredCountries"
      :input-options="{ placeholder }"
      valid-characters-only
      data-testid="phone-number-input"
      class="mb-1"
      @on-input="handleInput"
      @blur="handleBlur"
    />

    <div class="h-4">
      <ErrorMessage class="block text-xxs text-error" :name="name" />
    </div>
  </div>
</template>

<style lang="scss">
.vue-tel-input {
  @apply rounded  border-s-600 #{!important};

  &:focus-within {
    @apply border-p-800 ring-1 ring-p-800 ring-offset-0 #{!important};
  }

  input {
    @apply py-1 px-3 placeholder-s-400 #{!important};
  }

  .vti__dropdown:focus {
    @apply rounded outline-2 outline-offset-0 outline-p-800;
  }

  .vti__dropdown-item strong {
    @apply font-normal #{!important};
  }
}
</style>
