<script setup lang="ts">
import { Field } from 'vee-validate'
import { useSpecialIssueStore } from '@/stores/specialIssues'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  defaultValue: {
    type: String,
    default: '',
  },
})

const modelValue = ref(props.defaultValue)
const specialIssueStore = useSpecialIssueStore()

onMounted(() => {
  if (props.defaultValue) {
    emit('update:modelValue', props.defaultValue)
  }
  if (props.label === 'Special Issue' && specialIssueStore.title) {
    emit(
      'update:modelValue',
      `${specialIssueStore.id}-${specialIssueStore.title}`,
    )
  }
})
</script>

<template>
  <div>
    <label class="block">
      <Field
        ref="field"
        :name="name"
        class="form-input-outline form-input w-full rounded border border-s-600 py-1 px-3 placeholder-s-400"
        type="hidden"
        :value="modelValue"
        @update:model-value="(value) => $emit('update:modelValue', value)"
        @change:model-value="(value) => $emit('update:modelValue', value)"
      />
    </label>
  </div>
</template>
