import lowerCase from 'lodash/lowerCase'
import FieldManager from '@/utils/field-manager'
import { FormidableField } from '@/models/formidable'
import { FORMIDABLE_FIELD_WEBSITE_URL } from '~/constants/app'

class FormidableForm {
  $config
  configuration
  formId
  httpClient
  fields: FormidableField[]
  defaultValues
  rootLineTitles

  /**
   * Constructor to create a new Formidable Form instance.
   * This is a private constructor, you shouldn't use this
   * directly. Instead, create a form via $this.$formidableFormBuilder.create(FORM_ID)
   * because we need some nuxt context to setup up the form.
   *
   * @param {String|Number} formId - Formidable form id
   * @param {String} $config
   * @param {Object} httpClient
   * @param {Object} defaultValues
   */
  constructor(
    formId: number,
    $config,
    httpClient,
    defaultValues = {},
    rootLineTitles = {},
  ) {
    this.formId = formId
    this.$config = $config
    this.rootLineTitles = rootLineTitles
    this.httpClient = httpClient
    this.defaultValues = defaultValues
  }

  /**
   * Get url to get the form configuration
   *
   * @returns String
   */
  getFormConfigurationUrl() {
    return `${this.$config.apiRestBasedEndpoint}/frm/v2/forms/${this.formId}/fields`
  }

  /**
   * Get url to create a new entry
   *
   * @returns String
   */
  getNewEntryUrl() {
    return `${this.$config.apiRestBasedEndpoint}/frm/v2/entries`
  }

  /**
   * Get url to get the form configuration
   *
   * @returns String
   */
  getFormSettingsUrl() {
    return `${this.$config.apiRestBasedEndpoint}/frm/v2/forms/${this.formId}`
  }

  /**
   * Create the rules for the vee-validate's Validation Provider
   *
   * @param {*} fieldConfiguration - field configuration with all the properties such name, type, and so on....
   * @returns
   */
  buildRules(fieldConfiguration) {
    const rules = []

    if (+fieldConfiguration.required) {
      rules.push('required')
    }

    if (fieldConfiguration.type === 'number') {
      rules.push(
        `between:${fieldConfiguration.field_options.minnum},${fieldConfiguration.field_options.maxnum}`,
      )
    }

    if (fieldConfiguration.type === 'email') {
      rules.push('email')
    }

    return rules.join('|')
  }

  /**
   * Get the default value for the input. It uses the
   * property `defaultValues` to know if the is a default
   * value for the form input
   *
   * @param {String} name - form name
   * @param {String} type
   * @param {String} label
   * @returns mixed
   */
  getValue(name: string, type: string, label: string) {
    if (
      type === 'hidden' &&
      FORMIDABLE_FIELD_WEBSITE_URL === label.toLowerCase()
    ) {
      return window.location.pathname
    }
    const key = Object.keys(this.defaultValues).find((_key) => _key === name)
    if (key) return this.defaultValues[key]
    if (['checkbox', 'file'].includes(type)) return []
    return ''
  }

  getDefaultValue(value: string, type: string, options?: any) {
    const checkbox: { value?: string } =
      type === 'checkbox' &&
      Array.from(options)?.find((_option: { value: string }) => {
        return Object.values(this.defaultValues)?.some(
          (_value) => lowerCase(_value as string) === lowerCase(_option.value),
        )
      })
    if (checkbox) {
      return [checkbox?.value]
    }
    return value
  }

  getColumns(value) {
    if (value === 'frm_two_col') return 2
    if (value === 'frm_three_col') return 3
    return 1
  }

  getType(type) {
    if (type === 'phone') return 'tel'
    return type
  }

  getErrorMessages(fieldOptions) {
    const { invalid } = fieldOptions
    return { invalid }
  }

  setDefaultValue(config) {
    if (config.type === 'checkbox') {
      this.defaultValues[config.field_key] = []
    } else {
      this.defaultValues[config.field_key] =
        config.field_options.dyn_default_value
    }
  }

  /**
   * Build the configuration for each field based on the response
   * of the formidable entries' request.
   */
  buildFieldConfiguration() {
    this.fields = this.configuration
      .filter((f) => f.type !== 'end_divider')
      .map((config) => {
        const { field_options: fieldOptions } = config
        const fieldManager = new FieldManager(
          this.configuration,
          window.location.pathname,
          config,
          {},
        )
        const id = `field${config.id}`
        this.setDefaultValue(config)
        return {
          name: config.field_key,
          id,
          in_section: fieldOptions.in_section,
          value: this.getValue(config.field_key, config.type, config.name),
          rawValue: undefined,
          label: config.name,
          type: this.getType(config.type),
          options: config.options,
          columns: this.getColumns(fieldOptions.align),
          fieldOptions,
          errorMessages: this.getErrorMessages(fieldOptions),
          defaultValue: this.getDefaultValue(
            fieldOptions.dyn_default_value,
            config.type,
            config.options,
          ),
          required: !!+config.required,
          placeholder: fieldOptions.placeholder,
          rules: this.buildRules(config),
          field_order: config.field_order,
          visible: fieldManager.isVisible(),
          fieldManager,
        }
      })

    /**
     * we add a field to validate spam against the form
     */
    this.fields.push({
      for: '',
      in_section: '',
      id: `frm_email_${this.formId}`,
      name: 'frm_verify',
      value: '',
      rawValue: '',
      label: 'If you are human, leave this field blank.',
      type: 'hidden',
      options: '',
      component: '',
      fieldOptions: { classes: '' },
      defaultValue: '',
      required: false,
      placeholder: '',
      rules: '',
      field_order: '',
      visible: false,
      fieldManager: undefined,
    })
  }

  async uploadToMediaLibrary(files): Promise<string[]> {
    const formData = new FormData()
    files.forEach(({ file }, index) => {
      formData.append(`file-${index}`, file, file.name)
    })

    return (await $fetch(
      `${this.$config.apiRestEndpoint}/formidable/${this.formId}/upload`,
      {
        method: 'POST',
        body: formData,
      },
    )) as string[]
  }

  /**
   * Reset all field's values
   */
  reset() {
    this.fields = this.fields.map((field) => ({
      ...field,
      value: ['checkbox', 'file'].includes(field.type) ? [] : '',
    }))
  }

  /**
   * Create a formData with the data of all fields
   * to be sent to the backend
   *
   * @returns FormData
   */
  getFormData() {
    const formData = new FormData()
    formData.append('form_id', this.formId)

    this.fields
      .filter((field) => field.type !== 'break')
      .forEach((field) => {
        if (['checkbox', 'file'].includes(field.type)) {
          ;(field.value as string[]).forEach((option) => {
            formData.append(`${field.name}[]`, option)
          })
          return
        }

        let value = (field.rawValue || field.value) as string
        if (field.type === 'tel') {
          // We remove whitespace in the phone number because formidable form
          // throws an error if the phone number has whitespace
          value = value.replace(/ /g, '')
        }
        formData.append(field.name, value || '')
      })

    return formData
  }

  /**
   * This method upload the files to the media library before sending the information to Formidable
   *
   * Notes:
   *  - We assume there's only on file input field per form
   *  - Formidable expects a list of attachments id's
   */
  async uploadFiles() {
    const fileInput = this.fields.find((field) => field.type === 'file')
    if (fileInput) {
      fileInput.value = await this.uploadToMediaLibrary(fileInput.value)
    }
  }

  /**
   * Send the information to the backend.
   */
  async submit() {
    /**
     * We validate that the field we use against spam is empty, otherwise we do not send the form.
     */
    const isSpam = this.fields.some(
      (_field) => _field.name === 'frm_verify' && _field.value,
    )
    if (isSpam) return

    await this.uploadFiles()

    await this.httpClient.request({
      baseUrl: this.getNewEntryUrl(),
      path: '',
      method: 'post',
      body: this.getFormData(),
    })
  }

  /**
   * Create a Formidable Form's form instance. You should always
   * use the formidable form nuxt plugin to create new instances.
   *
   * @param {String|Number} formId
   * @param {Object} $config
   * @param {Object} httpClient
   * @param {Object} defaultValues
   * @returns
   */
  static async create(formId, $config, httpClient, defaultValues) {
    const form = new FormidableForm(formId, $config, httpClient, defaultValues)

    const configuration = await $fetch(form.getFormConfigurationUrl())

    const settings: any = await $fetch(form.getFormSettingsUrl())

    form.configuration = Object.values(configuration)
    form.rootLineTitles = settings.options.rootline_titles
    form.buildFieldConfiguration()

    return form
  }
}

export default FormidableForm
