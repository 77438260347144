<script setup lang="ts">
import { Field, ErrorMessage } from 'vee-validate'
import { PropType } from 'vue'
import { CheckboxGroupOption } from '@/models/formidable'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Array as PropType<string[]>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  rules: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  validateOnBlur: {
    type: Boolean,
    default: true,
  },
  columns: {
    type: Number,
    default: 2,
    validator(value: number) {
      return [1, 2, 3].includes(value)
    },
  },
  options: {
    type: Array as PropType<CheckboxGroupOption[]>,
  },
  showToggle: {
    type: Boolean,
    default: false,
  },
  defaultValue: {
    type: Array as PropType<string[]> | any,
    default: null,
  },
})

const NEWSLETTER_LABEL = 'Subscribe to the Benchmark Newsletter'

const emit = defineEmits(['update:modelValue'])

const gridColsClass = computed(() => {
  if (props.columns === 1) return 'grid-cols-1'
  if (props.columns === 2) return 'grid-cols-2'
  if (props.columns === 3) return 'grid-cols-3'
})

const { toggleAll, isToggleOn } = useToggleField({
  fieldsetName: props.name,
  fieldsetRules: props.rules,
  fieldsetOptions: props.options,
})
const handleUpdateValue = (value) => {
  emit('update:modelValue', value)
}

const getDisableCheckboxNewsletter = (_option) => {
  return (
    _option.label === NEWSLETTER_LABEL &&
    Object.values(props.defaultValue)?.some(
      (_value) => _value === NEWSLETTER_LABEL,
    )
  )
}

onMounted(() => {
  if (props.defaultValue?.length) {
    handleUpdateValue(props.defaultValue)
  }
})
</script>

<template>
  <div>
    <fieldset class="mb-1 block">
      <legend v-if="label" class="mb-6 flex items-center gap-x-2.5 md:mb-3">
        <BaseToggle
          v-if="showToggle"
          :is-active="isToggleOn"
          :on-click="toggleAll"
        />
        <span class="block text-xs font-semibold">
          {{ label }} <span v-if="required" class="text-error">*</span>
        </span>
      </legend>

      <section class="grid gap-x-3 gap-y-1" :class="gridColsClass">
        <label
          v-for="(option, id) in options"
          :key="id"
          class="flex cursor-pointer items-center text-xs"
        >
          <Field
            :name="name"
            :label="label"
            :rules="rules"
            class="no-outline-on-click form-checkbox mr-1.5 h-5 w-5 cursor-pointer rounded focus:ring-2 focus:ring-p-500"
            :class="[
              getDisableCheckboxNewsletter(option)
                ? 'text-s-400'
                : 'text-p-500',
            ]"
            type="checkbox"
            :value="option.value"
            :validate-on-blur="validateOnBlur"
            :disabled="getDisableCheckboxNewsletter(option)"
            @update:model-value="handleUpdateValue"
          />

          <span v-if="option.label !== NEWSLETTER_LABEL">
            {{ option.label }}
          </span>

          <span v-else>
            Subscribe to the
            <span class="font-bold text-p-500">Benchmark</span>
            Newsletter
          </span>
        </label>
      </section>
    </fieldset>
    <div class="h-4">
      <ErrorMessage :name="name">
        <span class="block text-xxs text-error-light">
          Please check at least one option from the list.
        </span>
      </ErrorMessage>
    </div>
  </div>
</template>
