<script setup lang="ts">
import { Form, SubmissionHandler } from 'vee-validate'
import { useGoogleAnalyticsFormSubmitEvent } from '~/composables/google-analytics'
import { getAdaptedFormidableErrors } from '@/utils/adapters'
import { FormidableField } from '@/models/formidable'
import BaseInput from '@/components/common/BaseInput.vue'
import BaseSelect from '@/components/common/BaseSelect.vue'
import BaseTextArea from '@/components/common/BaseTextArea.vue'
import BasePhoneInput from '@/components/common/BasePhoneInput.vue'
import BaseCheckboxGroup from '@/components/common/BaseCheckboxGroup.vue'
import FormidableForm from '@/utils/formidable-form'
import BaseInputHidden from '@/components/common/BaseInputHidden.vue'
import IconAngleRight from '@/assets/icons/angle-right.svg'
import IconAngleLeft from '@/assets/icons/angle-left.svg'

const router = useRouter()

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  successMessage: {
    type: String,
    required: false,
  },
  defaultValues: {
    type: Object,
    default: () => ({}),
  },
  fieldExtraClasses: {
    type: String,
    default: '',
  },
  fullWidthToButton: {
    type: Boolean,
    default: false,
  },
  hideFormOnSubmitSuccess: {
    type: Boolean,
    default: false,
  },
  textButton: {
    type: String,
    default: 'Send',
  },
  showTabSection: {
    type: Boolean,
    default: true,
  },
  showCustomButton: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['done', 'formSendSuccess', 'page-number-change'])
const showSuccessMessage = ref(false)

const form = ref<FormidableForm>()
const pages = ref<any[]>()
const currentStep = ref<number>(1)
const myForm = ref()

const textPaginationButton = computed(() =>
  isLastPage.value ? 'Submit' : 'Next Step',
)

const isLastPage = computed(() => currentStep.value === pages.value.length)

const submitClassButton = computed(() => {
  if (isLastPage.value && props.showCustomButton) {
    return 'mx-12'
  }
})

onMounted(async () => {
  form.value = await useFormidableForm(props.formId, props.defaultValues)
  getPages()
  emit('done')
})

const handleSubmit: SubmissionHandler = async (
  _values,
  { resetForm, setErrors },
) => {
  try {
    await form.value.submit()
    useGoogleAnalyticsFormSubmitEvent(props.formId)
    resetForm()
    emit('formSendSuccess')
    showSuccessMessage.value = true
  } catch ({ data }) {
    showSuccessMessage.value = false
    const errors = getAdaptedFormidableErrors({ errors: data?.message, form })
    setErrors(errors)
  }
}

/**
 * Get the properties according to the field type.
 * This is to avoid the bug when having a group
 * of checkboxes from formidable.
 */
const getProps = (field: FormidableField) => {
  if (field.type === 'checkbox') {
    const props = { ...field }
    delete props.type
    return props
  }

  if (field.type === 'text' || field.type === 'hidden') {
    const fieldProps = { ...field }

    fieldProps.defaultValue = props.defaultValues[fieldProps.label]

    return fieldProps
  }

  return field
}

const getRules = (field: FormidableField) => {
  const customRuleName = 'business-email'
  if (field.fieldOptions.classes.includes(customRuleName)) {
    return field.rules + '|' + customRuleName
  }
  return field.rules
}

/**
 * Translate formidable classes to tailwind classes.
 */
const getFieldClasses = (
  classes: string,
  verifyField: string,
  type: string,
  isVisible: boolean,
) => {
  /**
   * Validate if hidden fields are sent to the form or the validation field against spam to hide them from the user.
   */
  if (
    ['hidden', 'verify'].includes(classes) ||
    ['verify', 'frm_verify'].includes(verifyField) ||
    type === 'hidden' ||
    !isVisible
  ) {
    return `col-span-12`
  }
  if (classes.includes('frm6')) {
    return `col-span-12 lg:col-span-6 ${props.fieldExtraClasses}`
  }
  return `col-span-12 ${props.fieldExtraClasses}`
}

/**
 * Get the component that matches a Formidable field.
 */
const getComponent = (type: string) => {
  if (type === 'submit') return
  const supported = {
    select: BaseSelect,
    textarea: BaseTextArea,
    checkbox: BaseCheckboxGroup,
    tel: BasePhoneInput,
    default: BaseInput,
    hidden: BaseInputHidden,
  }
  return supported[type] || supported.default
}

const goToHome = () => {
  router.push('/')
}

const getPages = () => {
  if (!form.value) return []

  pages.value = generateTabs()
  generatePages()

  if (pages.value.length > 0) {
    pages.value[0].isActive = true
  }
}

const generateTabs = () => {
  return Object.entries(form.value.rootLineTitles)
    .map(([key, value], index) => ({
      key,
      value,
      pageNumber: index + 1,
      isActive: false,
      order: getOrderForTab(key),
      sections: null,
    }))
    .sort((a, b) => a.order - b.order)
}

const getOrderForTab = (key) => {
  return Number(
    form.value.fields.find((field) => field.id === `field${key}`)
      ?.field_order || 0,
  )
}

const generatePages = () => {
  for (const [i, actual] of pages.value.entries()) {
    const {
      hide_field: hideField,
      hide_field_cond: hideFieldCond,
      hide_opt: hideOpt,
    } = form.value.configuration.find((c) => c.id === actual.key)
      ?.field_options || {}
    const next = pages.value[i + 1]
    actual.hideField = hideField
    actual.hideFieldCond = hideFieldCond
    actual.hideOpt = hideOpt
    actual.sections = getSectionsForPage(actual.order, next?.order)
  }
}

const getSectionsForPage = (actualOrder, nextOrder) => {
  return form.value.fields.filter((field) =>
    isFieldInRange(field, +actualOrder, +nextOrder),
  )
}

const isFieldInRange = (field, actualOrder, nextOrder) => {
  const order = +field.field_order
  return nextOrder
    ? order > actualOrder && order < nextOrder
    : order > actualOrder
}

const submitPage = async () => {
  const isValid = await myForm.value?.validate()
  if (isValid.valid) {
    if (isLastPage.value) {
      try {
        await form.value.submit()
        useGoogleAnalyticsFormSubmitEvent(props.formId)
        emit('formSendSuccess', form.value.fields)
        showSuccessMessage.value = true
      } catch ({ data }) {
        showSuccessMessage.value = false
        const errors = getAdaptedFormidableErrors({
          errors: data,
          form,
        })
        logError(errors)
      }
    } else {
      moveToNextStep()
    }
  }
}

const moveToNextStep = () => {
  currentStep.value++
  deactivateAllTabs()
  activateCurrentTab()
  emit('page-number-change', currentStep.value)
}

const deactivateAllTabs = () => {
  pages.value.forEach((page) => {
    page.isActive = false
  })
}

const activateCurrentTab = (isBack = false) => {
  const currentPage = pages.value[currentStep.value - 1]
  if (currentPage.hideField?.length) {
    const field = form.value.fields.find(
      (f) => f.id === `field${currentPage.hideField[0]}`,
    )
    const shouldMoveToNextStep =
      currentPage.hideFieldCond[0] === '!='
        ? !field?.value.includes(currentPage.hideOpt[0])
        : field?.value.includes(currentPage.hideOpt[0])

    if (shouldMoveToNextStep) {
      if (pages.value.length > currentStep.value) {
        isBack ? moveToBackStep() : moveToNextStep()
      } else {
        submitPage()
      }
    } else {
      activatePage(currentPage)
    }
  } else {
    activatePage(currentPage)
  }
}

const activatePage = (page) => {
  page.isActive = true
}

const goToPage = async (pageNumber) => {
  if (currentStep.value === pageNumber) {
    return
  }

  const isValid = await myForm.value?.validate()

  if (isValid.valid) {
    currentStep.value = pageNumber
    deactivateAllTabs()
    activateCurrentTab()
  }
}

const moveToBackStep = () => {
  currentStep.value--
  deactivateAllTabs()
  activateCurrentTab(true)
  emit('page-number-change', currentStep.value)
}

const changeValue = () => {
  const formValues = getCurrentFormValues()
  form.value.fields.forEach((field) => {
    field.fieldManager?.setFormValues(formValues)
    field.visible = field.fieldManager?.isVisible()
  })
}

const getCurrentFormValues = () => {
  if (pages.value.length === 0) {
    return form.value.fields.reduce((hashTable, field) => {
      return {
        ...hashTable,
        [field.id.replace('field', '')]: field.rawValue || field.value,
      }
    }, {})
  }
  return pages.value.reduce((acc, item) => {
    item.sections.forEach((section) => {
      acc[section.id.replace('field', '')] = section.rawValue || section.value
    })
    return acc
  }, {})
}
</script>

<template>
  <Form
    v-if="form && pages.length === 0"
    v-slot="{ isSubmitting, meta }"
    :initial-values="form.defaultValues"
    @submit="handleSubmit"
  >
    <section
      v-if="successMessage && showSuccessMessage"
      class="mt-5 flex min-h-[400px] flex-col items-center justify-center"
    >
      <BaseFormSuccessMessage
        :message="successMessage"
        :show-message="showSuccessMessage"
      />
      <BaseButton variant="primary" @click="goToHome">
        Return to Homepage
      </BaseButton>
    </section>
    <div v-else>
      <h1 v-if="title" class="mb-10 text-2xl font-bold">
        {{ title }}
      </h1>

      <div class="grid grid-cols-12 gap-x-5">
        <section
          v-for="field in form.fields"
          :key="field.id"
          :class="
            getFieldClasses(
              field.fieldOptions.classes,
              field.name,
              field.type,
              field.visible,
            )
          "
        >
          <component
            :is="getComponent(field.type)"
            v-if="field.visible"
            v-bind="getProps(field)"
            v-model="field.value"
            @change="changeValue"
          />
        </section>
      </div>

      <section class="flex justify-end">
        <BaseButton
          :class="fullWidthToButton ? 'w-full' : ''"
          variant="primary"
          type="submit"
          :disabled="!meta.valid"
          :loading="isSubmitting"
        >
          {{ textButton }}
        </BaseButton>
      </section>
    </div>
  </Form>
  <Form
    v-if="form && pages.length > 0"
    ref="myForm"
    v-slot="{ isSubmitting, meta }"
    :initial-values="form.defaultValues"
    keep-values
    @submit="submitPage"
  >
    <section
      v-if="successMessage && showSuccessMessage"
      class="mt-5 flex min-h-[400px] flex-col items-center justify-center"
    >
      <BaseFormSuccessMessage
        :message="successMessage"
        :show-message="showSuccessMessage"
      />
      <BaseButton variant="primary" @click="goToHome">
        Return to Homepage
      </BaseButton>
    </section>
    <div v-else>
      <BaseButton
        v-if="showCustomButton && currentStep > 1"
        class="!px-0 !no-underline"
        variant="text"
        @click="moveToBackStep"
      >
        <div
          class="flex items-center justify-center gap-2.5 text-base text-s-900"
        >
          <IconAngleLeft class="h-6 w-6" />
          <span> Back </span>
        </div>
      </BaseButton>

      <h1 v-if="title" class="mb-10 text-2xl font-bold">
        {{ title }}
      </h1>

      <div
        v-if="pages.length && showTabSection"
        class="mb-4 flex justify-center gap-14 text-sm font-bold"
      >
        <div
          v-for="(page, index) in pages"
          :key="index"
          class="flex-shrink-0 basis-[114px] select-none"
          :class="{ 'cursor-pointer': !page.isActive }"
          @click="goToPage(page.pageNumber)"
        >
          <div
            class="border-b-4 pb-1 uppercase"
            :class="[
              page.isActive
                ? 'border-b-p-500 text-black'
                : 'border-b-s-600 text-s-600',
            ]"
          >
            Step {{ page.pageNumber }}
          </div>
          <div
            class="pt-2"
            :class="[page.isActive ? 'text-black' : 'text-s-600']"
          >
            {{ page.value }}
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(page, index) in pages"
          :key="index"
          class="font-base mt-4 mb-3 px-12"
        >
          <template v-if="page.isActive">
            {{ page.value }}
          </template>
        </div>
      </div>

      <div v-for="page in pages" :key="page.key">
        <template v-if="page.isActive">
          <div
            class="grid grid-cols-12 gap-x-5"
            :class="showCustomButton ? 'px-12' : ''"
          >
            <section
              v-for="field in page.sections"
              :key="field.id"
              :class="
                getFieldClasses(
                  field.fieldOptions.classes,
                  field.name,
                  field.type,
                  field.visible,
                )
              "
            >
              <component
                :is="getComponent(field.type)"
                v-if="field.visible"
                v-bind="getProps(field)"
                v-model="field.value"
                :rules="page.isActive ? getRules(field) : ''"
                @change="changeValue"
              />
            </section>
          </div>
        </template>
      </div>

      <section class="flex justify-end" :class="submitClassButton">
        <BaseButton
          v-if="showCustomButton && !isLastPage"
          class="!px-0 !no-underline"
          variant="text"
          type="submit"
          :loading="isSubmitting"
          :disabled="!meta.valid"
        >
          <div class="flex items-center justify-center gap-2.5 text-base">
            <span> Next </span>
            <IconAngleRight class="h-6 w-6" />
          </div>
        </BaseButton>
        <BaseButton
          v-else
          :class="fullWidthToButton ? 'w-full' : ''"
          variant="primary"
          type="submit"
          :loading="isSubmitting"
          :disabled="!meta.valid"
        >
          {{ textPaginationButton }}
        </BaseButton>
      </section>
    </div>
  </Form>
</template>
